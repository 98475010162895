<template>
    <div class="info_box">
        <div class="title">
            <p>
                <span></span>
                发票信息
                <li
                    style="
                        color: rgb(245, 166, 35);
                        font-size: 12px;
                        display: inline-block;
                        margin-left: 20px;
                    "
                >
                    <i class="el-icon-warning"></i>
                    须核对发票信息是否正确
                </li>
            </p>
            <span></span>
        </div>
        <el-form label-position="left" label-width="85px" size="small">
            <div class="form_box">
                <div style="margin-right: 20px">
                    <el-form-item label="开票金额" required>
                        <el-input
                            style="width: 290px"
                            :placeholder="
                                canInvoice === ''
                                    ? '请输入开票金额'
                                    : '剩余可开票金额：' + canInvoice
                            "
                            v-model="form.invoiceAmount"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="开票类别" required>
                        <el-select
                            v-model="form.invoiceCategory"
                            style="width: 290px"
                            placeholder="请选择"
                        >
                            <el-option label="电子发票" :value="1"> </el-option>
                            <el-option label="纸质发票" :value="2"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开票类型" required>
                        <el-select
                            v-model="form.invoiceType"
                            style="width: 290px"
                            placeholder="请选择"
                        >
                            <el-option label="增值税普通发票" :value="1">
                            </el-option>
                            <el-option label="增值税专用发票" :value="2">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="签署公司" required>
                        <el-select
                            v-model="form.typeB"
                            style="width: 290px; margin-right: 27px"
                            clearable
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in $tableDataHandle.selectTypeB()"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="自定义"
                        required
                        v-if="form.typeB == 9"
                    >
                        <el-input
                            v-model="form.contractB"
                            placeholder="请输入"
                            style="width: 290px; margin-right: 27px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="收件人"
                        :required="form.invoiceCategory == 2"
                    >
                        <el-input
                            style="width: 290px"
                            placeholder="输入收件人"
                            v-model="form.receiveName"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="收件电话"
                        :required="form.invoiceCategory == 2"
                    >
                        <el-input
                            style="width: 290px"
                            placeholder="输入收件电话"
                            v-model="form.receivePhone"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="收件邮箱"
                        :required="form.invoiceCategory == 1"
                    >
                        <el-input
                            style="width: 290px"
                            placeholder="输入收件邮箱"
                            v-model="form.receiveEmail"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="收件地址"
                        :required="form.invoiceCategory == 2"
                    >
                        <el-input
                            style="width: 350px"
                            placeholder="输入收件地址"
                            v-model="form.receiveAddress"
                        ></el-input>
                    </el-form-item>
                </div>
                <div>
                    <el-form-item label="开票名称" required>
                        <el-select
                            allow-create
                            filterable
                            :remote-method="remoteMethod"
                            remote
                            v-model="value"
                            size="small"
                            style="width: 290px"
                            placeholder="请选择搜索或直接输入"
                            value-key="invoiceName"
                            :filter-method="dataFilter"
                            @change="getDetails"
                            ref="searchSelect"
                            @clear="remoteMethod"
                            clearable
                        >
                            <el-option
                                v-for="item in invoiceList"
                                :key="item.id"
                                :label="item.invoiceName"
                                :value="item"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="税号" required>
                        <el-input
                            style="width: 290px"
                            placeholder="输入税号"
                            v-model="form.dutyNo"
                        ></el-input>
                    </el-form-item>
                    <div v-if="form.invoiceType == 2">
                        <el-form-item label="开户银行" required>
                            <el-input
                                style="width: 290px"
                                placeholder="输入开户银行"
                                v-model="form.depositBank"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="银行账户" required>
                            <el-input
                                style="width: 290px"
                                placeholder="输入银行账户"
                                v-model="form.bankAccount"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="公司电话" required>
                            <el-input
                                style="width: 290px"
                                placeholder="输入公司电话"
                                v-model="form.companyPhone"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="注册地址"
                            required
                            style="width: 100%"
                        >
                            <el-input
                                style="width: 290px"
                                placeholder="输入注册地址"
                                v-model="form.registerAddress"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="备注">
                        <el-input
                            type="textarea"
                            style="width: 290px"
                            placeholder="输入备注"
                            v-model="form.remark"
                            :rows="3"
                        ></el-input>
                    </el-form-item>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import { invoiceList } from '@/api/contr/invoice.js';
export default {
    data() {
        return {
            form: {
                invoiceAmount: '',
                invoiceName: '',
                invoiceType: '',
                receiveName: '',
                receivePhone: '',
                receiveAddress: '',
                receiveEmail: '',
                dutyNo: '',
                depositBank: '',
                bankAccount: '',
                companyPhone: '',
                registerAddress: '',
                invoiceCategory: '',
                remark: '',
                typeB: '',
                contractB: '',
            },
            value: {},
            invoiceList: [],
            canInvoice: '',
        };
    },
    mounted() {
        this.remoteMethod();
    },
    methods: {
        getApplyData(data) {
            if (
                data.contractInvoiceBOList &&
                data.contractInvoiceBOList.length > 0
            ) {
                let num = 0;

                data.contractInvoiceBOList.forEach((item) => {
                    if (
                        item.status == 1 ||
                        item.status == 2 ||
                        item.status == 5
                    ) {
                        num += item.invoiceAmount;
                    }
                });
                this.canInvoice = data.contractAmount - num;
            } else {
                this.canInvoice = data.contractAmount;
            }
        },
        getData(objs) {
            console.log(objs);
            let data = Object.assign({}, objs);

            this.form.invoiceType = data.invoiceType;
            this.form.invoiceCategory = data.invoiceCategory;
            this.form.receiveName = data.receiveName;
            this.form.invoiceAmount = data.invoiceAmount;
            this.form.receivePhone = data.receivePhone;
            this.form.receiveAddress = data.receiveAddress;
            this.form.receiveEmail = data.receiveEmail;
            this.form.dutyNo = data.dutyNo;
            this.form.depositBank = data.depositBank;
            this.form.bankAccount = data.bankAccount;
            this.form.companyPhone = data.companyPhone;
            this.form.registerAddress = data.registerAddress;
            this.form.invoiceName = data.invoiceName;
            this.form.typeB = data.typeB;
            this.form.contractB = data.contractB;
            this.form.remark = data.remark;
            let obj = {
                param: {
                    name: data.invoiceName,
                },
                pageSize: 10,
                pageNum: 1,
            };

            invoiceList(obj).then((res) => {
                console.log(res);
                this.value = res.data.list[0];
            });
        },
        remoteMethod(query) {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageSize: 10,
                pageNum: 1,
            };
            if (query) {
                data.param.name = query;
            }
            invoiceList(data).then((res) => {
                this.invoiceList = res.data.list;
            });
        },
        dataFilter(val) {
            this.value = val;
            if (val) {
                this.options = this.optionsFilter.filter((item) => {
                    if (
                        item.companyName.includes(val) ||
                        item.companyName
                            .toUpperCase()
                            .includes(val.toUpperCase())
                    ) {
                        return true;
                    }
                });
            } else {
                this.options = this.optionsFilter;
            }
        },

        getDetails() {
            if (this.value.invoiceName) {
                this.form.invoiceName = this.value.invoiceName;
                this.form.invoiceType = this.value.invoiceType;
                this.form.invoiceCategory = this.value.invoiceCategory;
                this.form.receiveName = this.value.receiveName;
                this.form.receivePhone = this.value.receivePhone;
                this.form.receiveAddress = this.value.receiveAddress;
                this.form.receiveEmail = this.value.receiveEmail;
                this.form.dutyNo = this.value.dutyNo;
                this.form.depositBank = this.value.depositBank;
                this.form.bankAccount = this.value.bankAccount;
                this.form.companyPhone = this.value.companyPhone;
                this.form.registerAddress = this.value.registerAddress;
                this.form.remark = this.value.remark;
                
            } else {
                this.form.invoiceName = this.value;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.info_box {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;

    .title {
        padding: 18px 0;
        display: flex;
        align-items: center;
        //     margin-top: 12px;
        font-size: 14px;
        color: #333;
        font-weight: 500;
        border-bottom: 1px solid#E5E5E5;
        margin-bottom: 18px;
        p {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #333333;
            font-weight: 500;
            span {
                display: inline-block;
                width: 2px;
                height: 14px;
                background: #2370eb;
                border-radius: 1px 1px 0px 0px;
                margin-right: 22px;
            }
        }
    }
    .el-form {
        width: 95%;
        margin: 0 auto;
        .el-form-item {
            width: 50%;
        }
    }
    .el-form:after {
        clear: both;
        display: block;
    }
    .form_box {
        display: flex;
    }
}
</style>
