<template>
    <div class="flow_box">
        <div class="title">
            <p>
                <span></span>
                请选择需要开票的回款计划
            </p>

            <el-button @click="onAddPlan" v-if="allMoney < data.contractAmount"
                >+ 新增计划</el-button
            >
        </div>
        <el-table
            ref="multipleTable"
            :data="tableData"
            :cell-class-name="cellcb"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="30"> </el-table-column>
            <el-table-column prop="returnTime" label="回款时间" width="180">
            </el-table-column>
            <el-table-column prop="contractA" label="付款账户" width="180">
            </el-table-column>
            <el-table-column prop="companyName" label="入账公司">
                <template slot-scope="scope">
                    <div>{{ scope.row.contractB }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="returnAmount" label="回款金额">
            </el-table-column>
            <el-table-column label="回款状态">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.returnStatus == 1 ? '未到款' : '已到款' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="开票状态">
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.billingStatus == 1
                                ? '未开票'
                                : scope.row.billingStatus == 2
                                ? '已开票'
                                : scope.row.billingStatus == 3
                                ? '审批中'
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <div slot="empty" class="empty" style="padding-top: 20px">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <el-dialog
            :visible.sync="dialog"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dilog_box"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增计划
                </div>
            </template>
            <el-form label-position="left" label-width="80px" size="small">
                <el-form-item label="回款金额" required>
                    <el-input
                        style="width: 240px"
                        placeholder="请输入"
                        v-model="form.returnAmount"
                        @input="
                            () => {
                                if (
                                    Number(form.returnAmount) + allMoney >
                                    data.contractAmount
                                ) {
                                    form.returnAmount = '0';
                                    this.$message.error(
                                        '回款金额必须小于合同金额'
                                    );
                                }
                                form.returnAmount = form.returnAmount.replace(
                                    /[^0-9.]/g,
                                    ''
                                );
                            }
                        "
                    ></el-input>
                    <div>
                        剩余回款计划金额：
                        {{ (data.contractAmount - allMoney).toFixed(2) }}
                    </div>
                </el-form-item>
                <el-form-item label="回款比例">
                    <div style="width: 240px">
                        {{
                            form.returnAmount
                                ? (
                                      (form.returnAmount /
                                          data.contractAmount) *
                                      100
                                  ).toFixed(2) + '%'
                                : '- -'
                        }}
                    </div>
                </el-form-item>
                <el-form-item label="回款年月" required>
                    <el-date-picker
                        style="width: 240px"
                        v-model="form.returnTime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="month"
                        placeholder="选择年月"
                        :picker-options="expireTimeOption"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="付款账户">
                    <el-input
                        style="width: 240px"
                        placeholder="请输入"
                        v-model="form.contractA"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div class="dialog_footer">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                        margin-left: auto;
                    "
                    @click="handleClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 30px;
                    "
                    size="mini"
                    @click="onSubmit"
                    >确定</el-button
                >
            </div>
        </el-dialog>
    </div>
    <!-- <div class="flow_box" v-else>
    <div class="empty" style="padding-top:20px">
      <img src="@/assets/zwsj.png" />
      <span style="display:block">当前合同已全部开票</span>
    </div>
  </div> -->
</template>

<script>
import { planList, getWater as waterList } from '@/api/contr/money.js';
import { addPlan } from '@/api/contr/money.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            multipleSelection: [],
            form: {
                contractA: '',
            },
            dialog: false,
            data: {},
            allMoney: 0,
            expireTimeOption: {
                disabledDate(date) {
                    // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                    return date.getTime() < Date.now();
                },
            },
        };
    },
    methods: {
        getData(data) {
            this.allMoney = 0;
            // console.log(data);
            this.data = data;
            this.form.contractA = data.contractA;
            let obj = {
                param: {
                    contractId: data.id,
                },
                pageNum: 0,
                pageSize: 0,
            };
            planList(obj).then((res) => {
                res.data.list.forEach((item) => {
                    this.allMoney += item.returnAmount;
                });

                this.tableData = res.data.list;

                // this.toggleSelection();
            });
        },
        cellcb(row) {
            if (row.row.billingStatus != 1) {
                return 'myCell';
            }
        },
        // toggleSelection() {
        //   let index = this.tableData.findIndex(
        //     (item) => item.id == sessionStorage.getItem("moneyId")
        //   );
        //   // console.log(this.tableData);
        //   this.$refs.multipleTable.toggleRowSelection(this.tableData[index]);
        //   this.handleSelectionChange([this.tableData[index]]);
        // },
        handleSelectionChange(val) {
            let arr = [],
                mount = 0;
            for (let i = 0; i < val.length; i++) {
                arr.push({ id: val[i].id });
                if (val[i].billingStatus == 1) {
                    mount += val[i].returnAmount;
                }
            }
            // console.log(mount);
            this.$emit('mount', mount);
            this.multipleSelection = arr;
        },
        onAddPlan() {
            // console.log(this.data);
            if (!this.data.id) {
                return this.$message.error('请选择关联合同');
            }
            this.dialog = true;
        },
        onSubmit() {
            if (!this.form.returnAmount) {
                return this.$message.error('请输入回款金额');
            }
            if (!this.form.returnTime) {
                return this.$message.error('请选择回款年月');
            }
            if (this.isEdit) {
                if (
                    this.allMoney +
                        Number(this.form.returnAmount) -
                        this.data.returnAmount >
                    this.planFormList[0].contractAmount
                ) {
                    return this.$message.error(
                        '回款金额应小于剩余可计划回款金额'
                    );
                }
                let data = {
                    param: {
                        id: this.data.id,
                        contractA: this.form.contractA
                            ? this.form.contractA
                            : this.data.contractA,
                        contractB: this.data.contractB,
                        returnAmount: this.form.returnAmount,
                        returnTime: this.form.returnTime,
                    },
                };
                updatePlan(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.handleClose();
                    }
                });
            } else {
                // console.log(this.planFormList);
                if (
                    this.allMoney + Number(this.form.returnAmount) >
                    this.data.contractAmount
                ) {
                    return this.$message.error(
                        '回款金额应小于剩余可计划回款金额'
                    );
                }
                let data = {
                    param: {
                        contractId: this.data.id,
                        contractA: this.form.contractA
                            ? this.form.contractA
                            : this.data.contractA,
                        contractB: this.data.contractB,
                        returnAmount: this.form.returnAmount,
                        returnTime: this.form.returnTime,
                    },
                };
                addPlan(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.getData(this.data);
                        this.handleClose();
                    }
                });
            }
        },
        handleClose() {
            for (const key in this.form) {
                this.form[key] = '';
            }
            this.dialog = false;
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
/deep/ .myCell .el-checkbox__input {
    display: none !important;
}
/deep/ .el-table-column--selection .cell {
    padding-left: 12px;
}
.flow_box {
    margin-top: 12px;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    .title {
        padding: 18px 0;
        display: flex;
        align-items: center;
        //     margin-top: 12px;
        font-size: 14px;
        color: #333;
        font-weight: 500;
        flex-direction: row;
        justify-content: space-between;
        p {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #333333;
            font-weight: 500;
            span {
                display: inline-block;
                width: 2px;
                height: 14px;
                background: #2370eb;
                border-radius: 1px 1px 0px 0px;
                margin-right: 22px;
            }
        }

        button {
            background: #fff;
            border-radius: 2px;
            padding: 0 7px 0 12px;
            height: 24px;
            color: #2370eb;
            border-color: #2370eb;
            float: right;
            margin-right: 15px;
        }
    }
}
.dilog_box {
    .el-form {
        width: 80%;
        margin: 20px auto 80px;
    }
}
.dialog_footer {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
