<template>
    <div class="applyInvoice">
        <div
            :style="{
                background:
                    'url(' + require('@/assets/bg2.png') + ') no-repeat',
                backgroundSize: 'cover',
            }"
            class="top_box"
        >
            <p class="top_title">
                <span>
                    <i class="iconfont icon-feiyongshenqing"></i>
                    申请发票</span
                >
                <span>
                    <el-button
                        @click="onClose()"
                        size="mini"
                        type="primary"
                        icon="el-icon-arrow-left"
                        style="
                            background: #fff;
                            color: #2370eb;
                            border-color: #2370eb;
                            margin-right: 20px;
                        "
                        >返回列表</el-button
                    ></span
                >
            </p>

            <div style="padding: 10px 0" v-if="disabled">
                <el-form
                    label-width="75px"
                    label-position="left"
                    style="margin-left: 52px; display: flex"
                    size="small"
                    ref="form"
                >
                    <el-form-item
                        label="客户"
                        required
                        style="margin-bottom: 0 !important; width: 375px"
                    >
                        <el-select
                            allow-create
                            filterable
                            :remote-method="remoteMethod"
                            remote
                            v-model="value"
                            size="small"
                            style="width: 240px"
                            placeholder="请搜索选择或者输入创建"
                            value-key="id"
                            :filter-method="dataFilter"
                            @change="getCompanyData"
                            ref="searchSelect"
                            @clear="setValueNull"
                            clearable
                        >
                            <el-option
                                v-for="item in options"
                                :key="item.id"
                                :label="item.companyName"
                                :value="item"
                            >
                            </el-option>
                        </el-select>
                        <!-- <el-select
                            filterable
                            v-model="contracId"
                            style="width: 240px; margin-right: 27px"
                            clearable
                            placeholder="请搜索选择或者输入创建"
                            @change="getData(contracId)"
                        >
                            <el-option
                                v-for="item in contractData"
                                :key="item.id"
                                :label="item.contractNo"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select> -->
                    </el-form-item>
                    <el-form-item
                        v-show="isShow === 2"
                        label="客户类型"
                        required
                        style="margin-bottom: 0 !important"
                    >
                        <el-radio-group v-model="form.enterpriseType">
                            <el-radio :label="1">企业</el-radio>
                            <el-radio :label="2">个人</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
            <div class="text_box" v-show="isShow === 1">
                <p v-if="disabled">
                    <el-form
                        label-width="75px"
                        label-position="left"
                        size="small"
                        ref="form"
                    >
                        <el-form-item
                            label="关联合同"
                            style="margin-bottom: 0 !important"
                        >
                            <el-select
                                filterable
                                v-model="contracId"
                                style="width: 240px; margin-right: 27px"
                                clearable
                                placeholder="请选择"
                                @change="getData(contracId)"
                            >
                                <el-option
                                    v-for="item in contractData"
                                    :key="item.id"
                                    :label="item.contractNo"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select> </el-form-item
                    ></el-form>
                </p>
                <p>
                    <span>合同编号</span>
                    <span>{{ data.contractNo ? data.contractNo : '- -' }}</span>
                </p>
                <p>
                    <span>客户名称</span>
                    <span>{{
                        data.companyName ? data.companyName : '- -'
                    }}</span>
                </p>
                <p>
                    <span>合同金额</span>
                    <span>{{
                        data.contractAmount ? data.contractAmount : '- -'
                    }}</span>
                </p>
            </div>
            <div class="text_box" v-show="isShow === 2">
                <el-form
                    label-width="75px"
                    label-position="left"
                    style="width: 100%; display: flex"
                    size="small"
                    ref="form"
                >
                    <el-form-item
                        label="联系人"
                        required
                        style="margin-bottom: 0 !important; width: 375px"
                    >
                        <el-input
                            style="width: 240px"
                            v-model="form.contactName"
                            placeholder="请输入联系人"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="联系方式"
                        required
                        style="margin-bottom: 0 !important"
                    >
                        <el-select
                            filterable
                            v-model="form.contactWay"
                            style="width: 80px; margin-right: 10px"
                            clearable
                            placeholder="方式"
                            @change="getData(contracId)"
                        >
                            <el-option label="电话" :value="1"></el-option>
                            <el-option label="微信" :value="2"></el-option>
                            <el-option label="邮箱" :value="3"></el-option>
                            <el-option label="QQ" :value="4"></el-option>
                            <el-option label="短信" :value="5"></el-option>
                            <el-option label="拜访" :value="6"></el-option>

                            <el-option label="座机" :value="9"></el-option>
                            <el-option label="其他" :value="7"></el-option>
                        </el-select>
                        <el-input
                            style="width: 150px"
                            v-model="form.contactContent"
                            placeholder="请输入联系内容"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="content_box">
            <!-- <div class="plan_box">
                <Plan ref="plan" @mount="mount" />
            </div> -->
            <div class="invoice_box">
                <Invoice ref="invoice" />
            </div>
        </div>

        <div class="bnt">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import Plan from './plan.vue';
import Invoice from './invoice.vue';
import { contractList } from '@/api/contr/contr.js';
import { contracGet } from '@/api/contr/contr.js';
import { invoiceAdd, invoiceGet, invoiceUpdate } from '@/api/contr/invoice.js';
import { addCustomer, getCustomerAdmin } from '@/api/user/ae/customer.js';
import { customerAddMy } from '@/api/user/ae/customer.js';
export default {
    components: {
        Plan,
        Invoice,
    },
    data() {
        return {
            contracId: '',
            contractData: [],
            data: {},
            upData: false,
            disabled: true,
            disabledContractId: false,
            optionsFilter: [],
            options: [],
            value: '',
            isShow: 0,
            form: {
                enterpriseType: 1,
                contactWay: 1,
            },
            customerId: '',
        };
    },
    created() {
        if (this.$route.query.companyName) {
            let data = {
                param: {
                    // adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 1,
                pageSize: 20,
            };
            data.param.name = this.$route.query.companyName;
            getCustomerAdmin(data).then((res) => {
                this.options = res.data.list;
                res.data.list.forEach((item) => {
                    if (item.id == this.$route.query.customerId) {
                        this.value = item;
                        this.getCompanyData(item);
                    }
                });
                this.optionsFilter = res.data.list;
                if (this.$route.query.id) {
                    this.upData = true;
                    let obj = {
                        param: {
                            id: this.$route.query.id,
                        },
                    };
                    invoiceGet(obj).then((res) => {
                        this.contracId =
                            res.data.contractId === 0
                                ? ''
                                : res.data.contractId;

                        if (res.data.contractId) {
                            this.isShow = 1;
                        }
                        this.getData(res.data.contractId);
                        this.$refs.invoice.getData(res.data);
                    });
                }
            });
        } else {
            this.getCustomerAdmin();
        }
    },
    mounted() {
        this.form = {
            enterpriseType: 1,
            contactWay: 1,
        };
        this.isShow = 0;
        this.customerId = '';
        this.optionsFilter = [];
        this.options = [];
        this.value = '';
    },
    methods: {
        // 合同列表
        getContractList(customerId) {
            let data = {
                param: {
                    // adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 0,
                pageSize: 0,
            };
            if (customerId) {
                data.param.customerId = customerId;
            }
            data.param.statusList = [12, 8, 7, 11, 10];
            contractList(data).then((res) => {
                if (res.code == 200) {
                    this.contractData = res.data.list;
                    if (this.$route.query.id) {
                        this.upData = true;
                        let obj = {
                            param: {
                                id: this.$route.query.id,
                            },
                        };
                        invoiceGet(obj).then((res) => {
                            this.contracId = res.data.contractId;
                            this.getData(res.data.contractId);
                            this.$refs.invoice.getData(res.data);
                        });
                    }
                    if (this.$route.query.contracId) {
                        this.contracId = this.$route.query.contracId;
                        this.getData(this.$route.query.contracId);
                        this.disabled = false;
                    }
                }
            });
        },
        remoteMethod(query) {
            this.getCustomerAdmin(query);
        },
        getCustomerAdmin(name) {
            sessionStorage.setItem('customerlist', 1);
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 1,
                pageSize: 20,
            };
            if (name) {
                data.param.name = name;
                delete data.param.adminId;
            }
            getCustomerAdmin(data).then((res) => {
                this.options = res.data.list;
                this.optionsFilter = res.data.list;
            });
        },
        dataFilter(val) {
            this.value = val;
            if (val) {
                this.options = this.optionsFilter.filter((item) => {
                    if (
                        item.companyName.includes(val) ||
                        item.companyName
                            .toUpperCase()
                            .includes(val.toUpperCase())
                    ) {
                        return true;
                    }
                });
            } else {
                this.options = this.optionsFilter;
            }
        },
        setValueNull() {
            this.getCustomerAdmin();
        },
        getCompanyData(e) {
            console.log(e);
            if (typeof e === 'string' && e !== '') {
                this.$refs.invoice.$data.canInvoice = '';
                this.$refs.invoice.$data.value = {};
                this.$refs.invoice.$data.form = {
                    invoiceAmount: '',
                    invoiceName: '',
                    invoiceType: '',
                    receiveName: '',
                    receivePhone: '',
                    receiveAddress: '',
                    receiveEmail: '',
                    dutyNo: '',
                    depositBank: '',
                    bankAccount: '',
                    companyPhone: '',
                    registerAddress: '',
                    invoiceCategory: '',
                    remark: '',
                    contractB: '',
                    typeB: '',
                };
                this.isShow = 2;
                return;
            } else if (e !== '') {
                this.getContractList(e.id);
                this.isShow = 1;
                return;
            } else if (e === '') {
                this.isShow = 0;
                return;
            }
        },
        mount(data) {
            this.$refs.invoice.$data.form.invoiceAmount = data;
        },
        getData(contracId) {
            if (contracId) {
                let data = {
                    param: {
                        id: contracId,
                    },
                };
                contracGet(data).then((res) => {
                    this.data = res.data;
                    this.$refs.invoice.getApplyData(res.data);
                    // this.$refs.plan.getData(this.data);
                });
            } else {
                this.data = {};
            }
        },
        onSubmit() {
            // let plan = this.$refs.plan.multipleSelection;
            let invoice = this.$refs.invoice.form;
            let canInvoice = this.$refs.invoice.canInvoice;
            if (!this.value) {
                return this.$message.error('请选择或输入客户');
            }
            // console.log(plan);
            // if (!this.contracId) {
            //     return this.$message.error('请选择关联合同');
            // }
            // if (!plan.length || plan.length == 0) {
            //     return this.$message.error('请选择回款计划');
            // }
            if (!invoice.typeB) {
                return this.$message.error('请选择签署公司');
            }
            if (invoice.typeB == 9 && !invoice.contractB) {
                return this.$message.error('请输入自定义签署公司');
            }
            if (!invoice.invoiceAmount) {
                return this.$message.error('请输入开票金额');
            } else if (canInvoice < invoice.invoiceAmount && this.contracId) {
                return this.$message.error(
                    '开票金额大于合同可开票金额，无法申请'
                );
            } else if (!invoice.invoiceName) {
                return this.$message.error('请输入开票名称');
            } else if (!invoice.invoiceType) {
                return this.$message.error('请选择发票类型');
            } else if (!invoice.invoiceCategory) {
                return this.$message.error('请选择开票类型');
            } else if (!invoice.dutyNo) {
                return this.$message.error('请输入税号');
            } else if (invoice.invoiceType == 2 && !invoice.depositBank) {
                return this.$message.error('请输入开户银行');
            } else if (invoice.invoiceType == 2 && !invoice.bankAccount) {
                return this.$message.error('请输入银行账号');
            } else if (invoice.invoiceType == 2 && !invoice.companyPhone) {
                return this.$message.error('请输入公司电话');
            } else if (invoice.invoiceType == 2 && !invoice.registerAddress) {
                return this.$message.error('请输入注册地址');
            } else if (invoice.invoiceCategory == 1 && !invoice.receiveEmail) {
                return this.$message.error('请输入收件邮箱');
            } else if (invoice.invoiceCategory == 2 && !invoice.receiveName) {
                return this.$message.error('请输入收件人');
            } else if (invoice.invoiceCategory == 2 && !invoice.receivePhone) {
                return this.$message.error('请输入收件人电话');
            } else if (
                invoice.invoiceCategory == 2 &&
                !invoice.receiveAddress
            ) {
                return this.$message.error('请输入收件人地址');
            } else {
                if (this.isShow == 1) {
                    let data2 = {
                        param: {
                            companyName: this.value.companyName,
                            adminId: sessionStorage.getItem('adminId'),
                        },
                    };
                    getCustomerAdmin(data2).then((res) => {
                        if (res.code == 200) {
                            if (res.data.total == 0) {
                                let datas = {
                                    param: {
                                        id: this.value.id,
                                    },
                                };
                                customerAddMy(datas).then((ress) => {
                                    if (ress.code == 200) {
                                        this.customerId = this.value.id;
                                        this.invoice(invoice);
                                    }
                                });
                            } else {
                                this.customerId = this.value.id;
                                this.invoice(invoice);
                            }
                        }
                    });
                } else if (this.isShow == 2) {
                    if (!this.value) {
                        return this.$message.error('请选择或输入客户');
                    }
                    if (!this.form.contactName) {
                        return this.$message.error('请输入联系人名称');
                    }
                    if (!this.form.contactWay) {
                        return this.$message.error('请选择联系方式');
                    }
                    if (!this.form.contactContent) {
                        return this.$message.error('请输入练习内容');
                    }

                    let data3 = {
                        param: {
                            customerDO: {
                                enterpriseType: this.form.enterpriseType,
                                companyName: this.value,
                            },
                            customerContactList: [
                                {
                                    companyName: this.value,
                                    contactName: this.form.contactName,
                                    customerContactWayDOList: [
                                        {
                                            contactContent:
                                                this.form.contactContent,
                                            contactWay: this.form.contactWay,
                                        },
                                    ],
                                },
                            ],
                        },
                    };

                    addCustomer(data3).then((res) => {
                        if (res.code == 200) {
                            this.customerId = res.data.id;
                            this.invoice(invoice);
                        }
                    });
                } else {
                    this.customerId = this.data.customerId;
                    this.invoice(invoice);
                }
            }
        },
        invoice(invoice) {
            let data = {
                param: invoice,
            };
            data.param.customerId = this.customerId;
            if (this.contracId) {
                data.param.contractId = this.contracId;
            }
            // data.param.contractReturnPlanDOList = plan;
            if (this.upData) {
                data.param.id = this.$route.query.id;
                data.param.status = 1;
                data.param.contractId = this.contracId || 0;
                invoiceUpdate(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('编辑成功，已重新提交');
                        this.onClose();
                    }
                });
            } else {
                invoiceAdd(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('添加成功');
                        this.onClose();
                    }
                });
            }
        },
        onClose() {
            this.$refs.invoice.canInvoice = '';
            window.history.go(-1);
        },
    },
};
</script>
<style lang="scss" scoped>
.applyInvoice {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .top_box {
        padding: 16px 0;
    }
    .top_title {
        font-size: 16px;
        color: #333333;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .icon-feiyongshenqing {
            color: #2370eb;
            margin-left: 19px;
            margin-right: 13px;
            font-weight: 600;
        }
    }
    .text_box {
        display: flex;
        padding-left: 52px;
        align-items: center;
        p {
            font-size: 14px;
            span:nth-child(1) {
                color: #666666;
                margin-right: 12px;
            }
            span:nth-child(2) {
                color: #333333;
                margin-right: 75px;
            }
        }
    }
    .invoice_box {
        margin-top: 12px;
        border-radius: 1px;
        flex: 1;
        background: #fff;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex: 1;
    }
    .bnt {
        background: #fff;
        padding: 14px 0;
        border-top: 1px solid #e5e5e5;
        text-align: center;
        button {
            padding: 5px 30px;
        }
    }
}
</style>
